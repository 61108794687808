import {Component} from '@angular/core';
import { MainLayoutComponent } from '../../main-layout/main-layout.component';


@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    date!: Date;

    constructor(public appMain: MainLayoutComponent) {}
}
