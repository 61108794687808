import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
//import { LobbyComponent } from './pages/home/lobby/lobby.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    //canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'app',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'player',
        loadChildren: () =>
          import('./pages/player/player.module').then((m) => m.PlayerModule),
      },
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
    ],
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
