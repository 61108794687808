import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../../../core/http/api.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-configuration-verification',
  templateUrl: './configuration-verification.component.html',
  styleUrls: ['./configuration-verification.component.css']
})
export class ConfigurationVerificationComponent implements OnInit {

  configId: string = '';
  showConfirmSaveDialog: boolean = false;

  public generalForm: FormGroup = this.formBuilder.group({
    enabled_verification: new FormControl({ value: true, enabled: true }),
    credit_bonus_value: new FormControl('3'),
    bonus_check: new FormControl({ value: true, enabled: true }),
  });

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getOperatorConfig();
  }

  getOperatorConfig() {
    this.api.list('operator/operator-config').subscribe((res) => {
      this.configId = res.data.id;
      this.generalForm.get("enabled_verification")?.setValue(res.data.personalVerification);
      this.generalForm.get("credit_bonus_value")?.setValue(res.data.bonusCreditValue);
      this.generalForm.get("bonus_check")?.setValue(res.data.bonusCreditEnabled);
    });
  }

  save() {
    this.showConfirmSaveDialog = true;
    const data = {
      personalVerification: this.generalForm.get("enabled_verification")?.value,
      bonusCreditValue: this.generalForm.get("credit_bonus_value")?.value,
      bonusCreditEnabled: this.generalForm.get("bonus_check")?.value
    }

    this.api.updateDirect('operator/operator-config', data).subscribe((res) => {
      //this.toastService.openSuccessToast('Cambios aplicados satisfactoriamente', () => {}, () => {});
      this.showConfirmSaveDialog = false;
    }, (err) => {
      console.log(err);
    });
  }

  closeDialog() {
    this.showConfirmSaveDialog = false;
  }

  showDialog() {
    this.showConfirmSaveDialog = true;
  }

}
