import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restart-password',
  templateUrl: './restart-password.component.html',
  styleUrls: ['./restart-password.component.css']
})
export class RestartPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
