<div class="layout-breadcrumb-container">
    <div class="layout-breadcrumb-left-items m-0 p-0">
        <!--<a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isStatic() && !appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>-->
        <p-breadcrumb [model]="breadcrumbs" styleClass="layout-breadcrumb"></p-breadcrumb>
    </div>
</div>
<style>
    ::ng-deep .layout-breadcrumb .p-menuitem-text {
        font-weight: 500 !important
    }
</style>