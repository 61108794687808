import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
  providers: [MessageService]
})
export class AppLayoutComponent implements OnInit {
  onNotify: Subscription | undefined;

  constructor(
    private readonly notification: NotificationService,
    private readonly message: MessageService
  ) {
    this.onNotify = this.notification.onNotifyEvent$.subscribe((response) => {
      this.message.add(response);
    });
  }

  ngOnInit(): void {}
}
