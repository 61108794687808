import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../../../core/http/api.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'app-configuration-credits',
  templateUrl: './configuration-credits.component.html',
  styleUrls: ['./configuration-credits.component.css']
})
export class ConfigurationCreditsComponent implements OnInit {

  configId: string = '';
  showConfirmSaveDialog: boolean = false;

  public generalForm: FormGroup = this.formBuilder.group({
    credit_purchase_value: new FormControl({ value: '3', disabled: true }),
    credit_sell_value: new FormControl('3'),
    credit_minimum_sell: new FormControl('3'),
    credit_maximum_sell: new FormControl('3'),
    credit_withdraw_value: new FormControl('3'),
    credit_minimum_withdraw: new FormControl('3'),
    credit_maximum_withdraw: new FormControl('3'),
  });

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getOperatorConfig();
  }

  getOperatorConfig() {
    this.api.list('operator/operator-config').subscribe((res) => {
      this.configId = res.data.id;
      this.generalForm.get("credit_purchase_value")?.setValue(res.data.creditPurchaseValue);
      this.generalForm.get("credit_sell_value")?.setValue(res.data.sellCreditValue);
      this.generalForm.get("credit_minimum_sell")?.setValue(res.data.minimumSellCredit);
      this.generalForm.get("credit_maximum_sell")?.setValue(res.data.maximumSellCredit);
      this.generalForm.get("credit_withdraw_value")?.setValue(res.data.withdrawCreditValue);
      this.generalForm.get("credit_minimum_withdraw")?.setValue(res.data.minimumWithdrawCredit);
      this.generalForm.get("credit_maximum_withdraw")?.setValue(res.data.maximumWithdrawCredit);
    });
  }

  save() {
    this.showConfirmSaveDialog = true;
    const data = {
      creditPurchaseValue: this.generalForm.get("credit_purchase_value")?.value,
      sellCreditValue: this.generalForm.get("credit_sell_value")?.value,
      minimumSellCredit: this.generalForm.get("credit_minimum_sell")?.value,
      maximumSellCredit: this.generalForm.get("credit_maximum_sell")?.value,
      withdrawCreditValue: this.generalForm.get("credit_withdraw_value")?.value,
      minimumWithdrawCredit: this.generalForm.get("credit_minimum_withdraw")?.value,
      maximumWithdrawCredit: this.generalForm.get("credit_maximum_withdraw")?.value,
    }

    this.api.updateDirect('operator/operator-config', data).subscribe((res) => {
      //this.toastService.openSuccessToast('Cambios aplicados satisfactoriamente', () => {}, () => {});
      this.showConfirmSaveDialog = false;
    }, (err) => {
      console.log(err);
    });
  }

  closeDialog() {
    this.showConfirmSaveDialog = false;
  }

  showDialog() {
    this.showConfirmSaveDialog = true;
  }

}
