import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable({providedIn: "root"})
export class AppBreadcrumbService {
    constructor(){
        console.log("Init Breadcrumb service")
    }

    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    
}
