<form [formGroup]="generalForm">
  <div style="padding: 0px 0px 10px 10px;">
    <div style="display: flex; flex-direction: column; padding: 20px 20px;">
      <span class="Title">Verificación Usuaria</span>
      <span class="ContentText">Al tener la verificación usuaria activa, tus jugadores no podrán utilizar el Cajero hasta compartir una identificación oficial la cual 
        deberá ser aprobada manualmente.<br>
        Podrás otorgar un bono a los usuarios para motivarlos a realizar la verificación. Los créditos que otorgues como bono serán descontados de tu reserva de 
        créditos.
      </span>
      <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
        <span class="LabelText" style="margin-bottom: 5px;">Activar verificación usuaria</span>
        <div style="width: 190px; display: flex; flex-direction: row;  justify-content: center;">
          No&nbsp;<p-inputSwitch formControlName="enabled_verification"></p-inputSwitch>&nbsp;Si
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Bono de créditos</span>
          <p-inputNumber formControlName="credit_bonus_value" [min]="0" [step]="0.50" mode="currency" currency="MXN" prefix="$ " [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <p-checkbox formControlName="bonus_check" [binary]="true" label="No otorgar bonos"></p-checkbox>

      <button label="Guardar" class="p-button-raised" pButton style="width: 200px; margin-top: 20px;" (click)="showDialog()"></button>


    </div>

  </div>
</form>
<p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [(visible)]="showConfirmSaveDialog" (onHide)="closeDialog()" >
  <p-header >
    <div style="justify-content: left; flex-direction: row; display: flex; align-items: left;">
      <i class="pi pi-exclamation-triangle" style="font-size: 2rem; padding-top: 5px;"></i>
      <label class="header_title" >Confirmar cambios</label>
    </div>
  </p-header>
  <div style="justify-content: left; display: flex; flex-wrap: wrap;">
    <span>Los cambios realizados se aplicarán en tus plataformas.</span>
    <span>¿Deseas continuar?</span>
  </div>
  <p-footer>
    <div style="display: flex; justify-content:right;" >
      <!-- <button type="button" style="width: 40%;" pButton label="" (click)="closeDialog()"></button> -->
      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text" label="Cancelar" (click)="closeDialog()"></button>
      <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text" label="Guardar y aplicar" (click)="save()"></button>
    </div>

  </p-footer>
</p-dialog>
