<form [formGroup]="casinoForm">
    <div class="flex flex-column pt-4 pb-4" >
        <span class="Title mb-4">Casino General</span>
        <span class="Title">Plataforma para Players</span>
        <span class="ContentText">Nombre con el que tus Players identificarán tu Casino, así como también la dirección web que estos utilizarán para acceder a su respectiva plataforma de Casino</span>
        <div class="mt-2 flex flex-column w-9" >
            <span class="LabelText mb-1">Nombre de Casino</span>
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <input pInputText *ngIf="!showLoadSkeleton" formControlName="casino_name" autocomplete="off" pInputText placeholder="Escribe el nombre de tu casino aquí" style="height:2.5rem" (ngModelChange)="changeSelect()">
            <small *ngIf="casinoForm.controls['casino_name'].hasError('minlength') || casinoForm.controls['casino_name'].hasError('maxlength')" class="p-error block">El nombre de tu casino debe tener de 5 a 30 caracteres.</small>
            <small *ngIf="casinoForm.controls['casino_name'].hasError('pattern')" class="p-error block">El nombre de tu casino solo puede tener letras, números y espacios.</small>
            <small *ngIf="casinoForm.controls['casino_name'].hasError('required') && !showLoadSkeleton" class="p-error block">Es necesario definir el nombre del casino</small>
        </div>
        <div class="mt-2 flex flex-column w-9" >
            <span class="LabelText mb-1">Dirección Web</span>
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <span class="p-input-icon-right" (click)="copyTextToClipBoard()">
              <i *ngIf="!showLoadSkeleton" class="pi pi-copy"></i>
              <input *ngIf="!showLoadSkeleton" class="disabledInput" formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:2.5rem" readonly>
            </span>
            <p-toast position="bottom-center" key="bc"></p-toast>
        </div>
        <p-divider></p-divider>
        <div class="mt-2 flex flex-column w-9">
            <span class="Title">Logotipo</span>
            <span class="ContentText">Esta imagen identificará tu Casino en múltiples partes de tus plataformas (Max. 1 MB y 600:180 px).</span>
            <div class="grayBackground border-round-sm mt-3 w-10 max-w-24rem" styleClass="p-card-shadow">
                <div class="p-2" *ngIf="showLoadSkeleton">
                    <p-skeleton  shape="rectangle" borderRadius="0px" height="11rem"></p-skeleton>
                </div>
                <div class="m-2 max-h-11rem overflow-hidden">
                    <p-image  imageClass=" w-full h-full" *ngIf="!showLoadSkeleton" [src]="logo ? logo : 'assets/images/no-photo-available.png'" alt="Image" [preview]="logo ? true : false"></p-image>
                  </div>
                <button class="mt-3 mb-3 mt-0 ml-2 mr-2 w-6" type="button" pButton label="Examinar..." (click)="loadLogo()">
                    <i class="pi pi-folder-open"></i>
                </button>
            </div>
        </div>
        <p-divider></p-divider>
        <span class="Title">Contacto</span>
        <span class="ContentText">La información registrada en los siguientes campos será ofrecida a tus Players como medios de contacto para soporte y atención a clientes.</span>
        <div class="mt-2 flex flex-column w-9">
            <span class="LabelText" style="margin-bottom: 5px;">Correo de contacto</span>
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <input *ngIf="!showLoadSkeleton" formControlName="contact_email" autocomplete="off" pInputText placeholder="Correo de contacto" style="height:2.5rem" (ngModelChange)="changeSelect()">
            <small *ngIf="casinoForm.controls['contact_email'].hasError('pattern')" class="p-error block">Introduce una dirección de correo válida.</small>
            <small *ngIf="casinoForm.controls['contact_email'].hasError('required')" class="p-error block">Es necesario definir un correo de contacto.</small>
        </div>
        <div class="mt-2 flex flex-column w-9">
            <div class="flex flex-row justify-content-between">
                <div class="mt-2 flex flex-column w-3 min-w-min mr-1" >
                    <span class="LabelText mb-1">Código de pais</span>
                    <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
                    <p-dropdown *ngIf="!showLoadSkeleton" formControlName="country_code" [options]="countries_code_catalog" optionLabel="ladaCode" optionValue="id" (ngModelChange)="changeSelect()" [filter]="false" filterBy="country"
                    [showClear]="false" placeholder="Seleccione un país">
                        <ng-template pTemplate="selectedItem" let-option>
                            <div class="country-item country-item-value flex">
                                <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + option.flagCode.toLowerCase()" />
                                <div class="ml-2">{{option.country}} {{option.ladaCode}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="country-item flex">
                                <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.flagCode.toLowerCase()" />
                                <div class="ml-2">{{country.country}} {{country.ladaCode}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="w-9 flex flex-column mt-2" >
                    <span class="LabelText mb-1">Teléfono de contacto</span>
                    <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
                    <input *ngIf="!showLoadSkeleton" formControlName="contact_phone" autocomplete="off" pInputText placeholder="Teléfono de contacto" style="height: 2.5rem;" (ngModelChange)="changeSelect()" (keydown)="validateNumber($event)">
                    <small *ngIf="casinoForm.controls['contact_phone'].hasError('minlength')" class="p-error block">El teléfono debe tener de 6 a 12 dígitos.</small>
                    <small *ngIf="casinoForm.controls['contact_phone'].hasError('maxlength')" class="p-error block">El teléfono debe tener de 6 a 12 dígitos.</small>
                    <small *ngIf="casinoForm.controls['contact_phone'].hasError('required')" class="p-error block">Es necesario definir un telefono de contacto.</small>
                    <small *ngIf="casinoForm.controls['contact_phone'].hasError('pattern')" class="p-error block">El teléfono solo acepta numeros.</small>

                </div>
            </div>
        </div>
        <div class="mt-2 flex flex-column w-9" >
            <span class="LabelText mb-1">Servicios de Mensajería:</span>
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <p-multiSelect *ngIf="!showLoadSkeleton" [options]="messaging_services_catalog" formControlName="messaging_services" display="chip" optionLabel="name" optionValue="id" (ngModelChange)="changeMessageService($event)" placeholder="Selecciona al menos una opción"></p-multiSelect>
            <small *ngIf="casinoForm.controls['messaging_services'].hasError('required')" class="p-error block">Debe seleccionar al menos un servicio de mensajería.</small>

        </div>
        <p-divider></p-divider>
        <span class="Title">Idioma y Horario</span>
        <span class="ContentText">La zona horaria y el idioma seleccionados serán los utilizados para tu Casino</span>
        <div class="mt-2 flex flex-column w-9" >
            <span class="LabelText mb-1">Zona horaria</span>
            <!-- <input formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly> -->
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <p-dropdown *ngIf="!showLoadSkeleton" formControlName="timezone" [options]="timezone_catalog" optionLabel="name" optionValue="id" [filter]="false" [showClear]="false" placeholder="Seleccione una zona horaria"  (ngModelChange)="changeSelect()"></p-dropdown>
        </div>
        <div class="mt-2 flex flex-column w-9">
            <span class="LabelText mb-1">Idioma</span>
            <!-- <input formControlName="web_direction" autocomplete="off" pInputText placeholder="Dirección Web" style="height:25px" readonly> -->
            <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
            <p-dropdown *ngIf="!showLoadSkeleton" formControlName="language" [options]="language_catalog" optionLabel="name" optionValue="id" (ngModelChange)="changeSelect()"
                [filter]="false" [showClear]="false" placeholder="Seleccione un idioma">
            </p-dropdown>
        </div>
        <p-button label="Guardar" icon="pi pi-save" [disabled]="isButtonDisabled" class="p-button-raised mt-4 w-13rem" (click)="showDialog()"></p-button>
    </div>
</form>
<p-toast position="top-center"></p-toast>
<!--dialog section-->
<div class="w-8">
    <p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [blockScroll]=true [(visible)]="showConfirmSaveDialog" (onHide)="closeDialog()" [dismissableMask]=true>
        <p-header >
          <div class="flex justify-content-start flex-row align-content-start">
            <i class="pi pi-exclamation-triangle" style="font-size: 2rem; padding-top: 5px;"></i>
            <label class="header_title" >Confirmar Cambios</label>
          </div>
        </p-header>
        <div style="justify-content: left; display: flex; flex-wrap: wrap;">
          <span>Los cambios realizados se aplicarán en tu plataforma de casino consultada.</span>
          <span class="mt-2">¿Deseas continuar?</span>
        </div>
        <p-footer>
          <div *ngIf="!showProgressBar" style="display: flex; justify-content:right;" >
            <!-- <button type="button" style="width: 40%;" pButton label="" (click)="closeDialog()"></button> -->
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text" label="Cancelar" (click)="closeDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text" label="Guardar y aplicar" (click)="save()"></button>
          </div>
          <p-progressBar *ngIf="showProgressBar" mode="indeterminate" [style]="{'height': '4px'}"></p-progressBar>
        </p-footer>
      </p-dialog>
</div>
