import { Component, OnInit, HostListener, Input  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/http/api.service';
import { Utilities } from 'src/app/core/tools/utilities';

@Component({
  selector: 'app-config-layout',
  templateUrl: './config-layout.component.html',
  styleUrls: ['./config-layout.component.css']
})
export class ConfigurationLayoutComponent implements OnInit {

  public isGeneralSelected: boolean = true;
  public isCasinoGeneralSelected: boolean = false;
  public isCreditsSelected: boolean = false;
  public isVerificationSelected: boolean = false;
  public screenWidth: number = 0;
  vertical = true;
  incomplete: boolean = false;
  isDisabled: boolean = true;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { 
    
    const incomplete = this.route.snapshot.queryParamMap.get('incomplete');
    if(incomplete){
      this.incomplete = true;
      this.getOperatorConfig();
    }
  }

  ngOnInit(): void {
    this.selectGeneral();
    this.responsiveScreen();
  }

  isDataSavedReceived = (e: any) => {
    if(e){
      this.getOperatorConfig();
    }
  }

  getOperatorConfig = () => {
    this.apiService.GetOperatorConfig().subscribe((response) => {
      const {timeZoneCatId, languageCatId, logoUrl, casinoName, logoCasinoUrl, contactEmail, countryCodeId, contactPhone, mesaggingServices, timeZoneCasinoCatId, languageCasinoCatId } = response.data;
      let requeredConfigData = {
        operator: {
          timeZoneCatId,
          languageCatId,
          logoUrl
        },
        casino: {
          casinoName,
          logoCasinoUrl,
          contactEmail,
          countryCodeId,
          contactPhone,
          mesaggingServices,
          timeZoneCasinoCatId,
          languageCasinoCatId,
        }
      }
      //requeredConfigData.casino.casinoName = null;
      let operator = Utilities.hasNull(requeredConfigData.operator);
      let casino = Utilities.hasNull(requeredConfigData.casino);
      if(operator || casino){
       this.isDisabled = true;
      }else{
        this.isDisabled = false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.responsiveScreen();
  }

  selectGeneral(){
    this.isGeneralSelected  = true;
    this.isCasinoGeneralSelected = false;
    this.isCreditsSelected = false;
    this.isVerificationSelected = false;
  }

  selectCredits(){
    this.isGeneralSelected  = false;
    this.isCasinoGeneralSelected = false;
    this.isCreditsSelected = true;
    this.isVerificationSelected = false;
  }

  selectVerification(){
    this.isGeneralSelected  = false;
    this.isCasinoGeneralSelected = false;
    this.isCreditsSelected = false;
    this.isVerificationSelected = true;
  }

  selectCasinoGeneral = () => {
    this.isGeneralSelected  = false;
    this.isCasinoGeneralSelected = true;
    this.isCreditsSelected = false;
    this.isVerificationSelected = false;
  }

  closeConfigLayout(){

    // this.isGeneralSelected = false;
    // this.isCreditsSelected = false;
    // this.isVerificationSelected = false;
    // navigate out of the config layout
  }

  responsiveScreen(){
    this.screenWidth = window.innerWidth;
    if(this.screenWidth < 768){
      this.vertical = false;
    }else{
      this.vertical = true;
    }
  }

  goToHome = () => {
    this.router.navigate(['/']);
  }

}
