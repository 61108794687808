<form [formGroup]="generalForm">
  <div style="padding: 0px 0px 10px 10px;">
    <div style="display: flex; flex-direction: column; padding: 5px 5px;">
      <span class="Title">Gestión de Créditos</span>
      <span class="SubTitle" style="margin-top: 15px;">Precio de créditos</span>
      <span class="ContentText">Precio actual al que compras créditos a tu proveedor.</span>
      <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
        <span class="LabelText" style="margin-bottom: 5px;">Valor venta de créditos</span>
        <p-inputNumber formControlName="credit_purchase_value" [min]="0" [step]="0.50" mode="currency" currency="MXN" prefix="$ " [showButtons]="false">
        </p-inputNumber>
      </div>
    </div>
    <p-divider></p-divider>

    <div style="display: flex; flex-direction: column; padding: 5px 5px;">

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <span class="SubTitle">Venta de créditos</span>
        <span class="ContentText">Precio actual al que vendes los créditos a tus jugadores, así como también los montos minimos y máximos que pueden comprar.</span>
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Valor venta de créditos</span>
          <p-inputNumber formControlName="credit_sell_value" [min]="0" [step]="0.50" mode="currency" currency="MXN" prefix="$ " [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Venta mínima</span>
          <p-inputNumber formControlName="credit_minimum_sell" [min]="0" [step]="0.50" suffix=" Creditos" [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Venta máxima</span>
          <p-inputNumber formControlName="credit_maximum_sell" [min]="0" [step]="0.50" suffix=" Creditos" [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

    </div>
    <p-divider></p-divider>

    <div style="display: flex; flex-direction: column; padding: 5px 5px;">

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <span class="SubTitle">Retiro de créditos</span>
        <span class="ContentText">Valor actual al que tus jugadores retiran los créditos, así como también los montos minimos y máximos que pueden retirar.</span>
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Valor retiro de créditos</span>
          <p-inputNumber formControlName="credit_withdraw_value" [min]="0" [step]="0.50" mode="currency" currency="MXN" prefix="$ " [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 5px;">
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Retiro mínimo</span>
          <p-inputNumber formControlName="credit_minimum_withdraw" [min]="0" [step]="0.50" suffix=" Creditos" [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 25px;">
        <div style="margin-top: 10px; width: 300px; display: flex; flex-direction: column;" >
          <span class="LabelText" style="margin-bottom: 5px;">Retiro máximo</span>
          <p-inputNumber formControlName="credit_maximum_withdraw" [min]="0" [step]="0.50" suffix=" Creditos" [showButtons]="true">
          </p-inputNumber>
        </div>
      </div>

      <p-button icon="pi pi-save" label="Guardar" class="p-button-raised w-13rem" (click)="showDialog()"></p-button>

    </div>
  </div>
</form>
<p-dialog appendTo ="body"  [style]="{width: '500px'}" [modal]="true" [draggable]="false" [(visible)]="showConfirmSaveDialog" (onHide)="closeDialog()" >
  <p-header >
    <div style="justify-content: left; flex-direction: row; display: flex; align-items: left;">
      <i class="pi pi-exclamation-triangle" style="font-size: 2rem; padding-top: 5px;"></i>
      <label class="header_title" >Confirmar cambios</label>
    </div>
  </p-header>
  <div style="justify-content: left; display: flex; flex-wrap: wrap;">
    <span>Los cambios realizados se aplicarán en tus plataformas.</span>
    <span>¿Deseas continuar?</span>
  </div>
  <p-footer>
    <div style="display: flex; justify-content:right;" >
      <!-- <button type="button" style="width: 40%;" pButton label="" (click)="closeDialog()"></button> -->
      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text" label="Cancelar" (click)="closeDialog()"></button>
      <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text" label="Guardar y aplicar" (click)="save()"></button>
    </div>

  </p-footer>
</p-dialog>
