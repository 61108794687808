import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { AppMenuComponent } from '../Components/MenuComponent/app.menu.component';
import { MenuService } from '../Services/app.menu.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
    selector: 'app-main',
    templateUrl: './main-layout.component.html',
    providers: [MessageService]
})
export class MainLayoutComponent {
    @ViewChild(AppMenuComponent) child: AppMenuComponent | undefined;
    onToastMessage: Subscription | undefined

    overlayMenuActive: boolean = false;

    staticMenuDesktopInactive = false;

    staticMenuMobileActive: boolean = false;

    sidebarActive = true;

    sidebarStatic = true;

    menuClick: boolean = false;

    menuHoverActive = false;

    topbarMenuActive: boolean = false;

    topbarItemClick: boolean = false;

    activeTopbarItem: any;

    configActive: boolean = false;

    configClick: boolean = false;

    rightMenuActive: boolean = false;

    rightMenuClick: boolean = false;

    searchActive: boolean = false;

    searchClick: boolean = false;

    activeInlineProfile: boolean = false;

    pinActive: boolean = true;

    screenWidth: number = window.innerWidth;

    constructor(private menuService: MenuService, private primengConfig: PrimeNGConfig, public app: AppComponent, private toast: ToastService, private message: MessageService) {
        this.onToastMessage = this.toast.onNotifyEvent$.subscribe($event => {
            this.doAction($event)
        } )
    }

    doAction($event:any): void {
        this.message.add($event);
    }

    ngOnInit() {
        window.onresize = () => this.screenWidth = window.innerWidth;
    }

    isClosedReceived(e: boolean) {
        if(e) this.child?.getCoorporateConfiguration();
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (this.rightMenuActive && !this.rightMenuClick) {
            this.rightMenuActive = false;
        }

        if (this.searchActive && !this.searchClick) {
            this.searchActive = false;
        }

        if (!this.menuClick) {
            if ((this.isSlim() || this.isHorizontal()) && !this.isMobile()) {
                this.menuService.reset();
                this.menuHoverActive = false;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.overlayMenuActive = false;
                this.staticMenuMobileActive = false;
            }
        }

        this.configClick = false;
        this.rightMenuClick = false;
        this.searchClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
    }

    onSidebarClick($event: any) {
        this.menuClick = true;
    }

    onToggleMenu(event: { preventDefault: () => void; }) {
        this.menuClick = true;

        if (this.overlayMenuActive) {
            this.overlayMenuActive = false;
        }

        if (this.sidebarActive) {
            this.sidebarStatic = !this.sidebarStatic;
        }

        event.preventDefault();
    }

    onSidebarMouseOver(event: any) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            this.sidebarActive = this.isDesktop();
            setTimeout(() => {
                this.pinActive = this.isDesktop();
            }, 200);
        }
    }

    onSidebarMouseLeave($event: any) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            setTimeout(() => {
                this.sidebarActive = false;
                this.pinActive = false;
            }, 250);
        }
    }

    onMenuButtonClick(event: { preventDefault: () => void; }) {
        this.menuClick = true;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onTopbarItemClick(event: { preventDefault: () => void; }, item: any) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event: { preventDefault: () => void; }) {
        event.preventDefault();
    }

    onRippleChange(event: { checked: boolean; }) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event: any) {
        this.configClick = true;
    }

    onRightMenuButtonClick() {
        this.rightMenuClick = true;
        this.rightMenuActive = true;
    }

    onRightMenuClick($event: any) {
        this.rightMenuClick = true;
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isSidebar() {
        return this.app.menuMode === 'sidebar';
    }

    isDesktop() {
        return this.screenWidth > 991;
    }

    isMobile() {
        return this.screenWidth <= 991;
    }
}
