<div class="layout-wrapper mt-4"  (click)="onLayoutClick()"
     [ngClass]="{'layout-static': app.menuMode === 'static',
                'layout-overlay': app.menuMode === 'overlay',
                'layout-slim': app.menuMode === 'slim',
                'layout-horizontal': app.menuMode === 'horizontal',
                'layout-sidebar': app.menuMode === 'sidebar',
                'layout-sidebar-static': app.menuMode === 'sidebar' && sidebarStatic,
                'layout-overlay-active': overlayMenuActive,
                'layout-mobile-active': staticMenuMobileActive,
                'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
                 'p-ripple-disabled': !app.ripple}">

    <div class="layout-main">
      <p-toast position="top-center" key="project" ></p-toast>
        <app-menu></app-menu>
        <div class="flex flex-row justify-content-between">
          <div>
            <app-breadcrumb></app-breadcrumb>
          </div>
          <div style="height: 65px;">
            <app-header-credits (messageEvent)="isClosedReceived($event)"></app-header-credits>
          </div>
        </div>


        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>

    </div>


</div>
