import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(private authService: AuthService) { 
    console.log('Init Interceptor Token');
  }

  //en el siguiente array se pondran las api url que no necesitan el bearer token para traer datos
  exceptions = [];

  isExcludedEndpoint = (endpoint: string): boolean => {
    for (const ex of this.exceptions)
      if(endpoint.includes(ex)) return true;
      return false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if(token && !this.isExcludedEndpoint(req.url)){
      let newHeaders = req.headers;
      newHeaders = newHeaders.set("Authorization", `Bearer ${token}`);
      let newRequest = req.clone({headers: newHeaders});
      return next.handle(newRequest);
    }else{
      return next.handle(req);
    }
  }
}
